import { Table } from "@tanstack/react-table";
import { Icon } from "components/icon/Icon";
import { IProduct } from "integrations/firebase/collections";

import { formatFirebaseTimestampDayTime } from "shared/utils";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import { useProjectDetails } from "../contexts/ProjectDetailsContext";
import { GenerateButton } from "./GenerateButton";
import { ExtractButton } from "./ExtractButton";
import { saveProductsAsExcel } from "../../../shared/services/ExportProductsService";
import { useAnalytics } from "../../../contexts/AnalyticsContext";

interface PreHeaderProps {
  table: Table<IProduct>;
}

export const PreHeader = ({ table }: PreHeaderProps) => {
  const { project } = useProjectDetails();
  const created = formatFirebaseTimestampDayTime(project?.createdAt!);
  const { t } = useTranslation(["books"]);
  const selectedProducts = table
    .getSelectedRowModel()
    .rows.map((row) => row.original);
  const { gaEvent } = useAnalytics();

  const handleExtractButtonClick = useCallback(() => {
    gaEvent({
      type: "export_books",
      payload: { books_count: selectedProducts.length },
    });
    saveProductsAsExcel(selectedProducts, project?.name || "project");
  }, [gaEvent, selectedProducts, project]);

  return (
    <div className="flex flex-row w-full justify-between items-center">
      <div className="flex flex-row gap-2 items-center">
        <Icon name="calendar" size="medium" />
        <p className="text-secondary-500">{created.date}</p>
        <p className="text-secondary-500 font-normal">{created.time}</p>
      </div>
      <div className="flex gap-2 items-center">
        {selectedProducts.length > 0 ? (
          <p className="text-secondary-500 ml-4 text-nowrap">
            {t("books:selected", { count: selectedProducts.length })}
          </p>
        ) : null}

        <GenerateButton products={selectedProducts} />

        <ExtractButton
          onClick={handleExtractButtonClick}
          disabled={selectedProducts.length === 0}
        />
      </div>
    </div>
  );
};

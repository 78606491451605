import { Navigate, Route, Routes } from "react-router-dom";
import { UserRole } from "__generated__/models";
import { useAuth } from "contexts/AuthContext";
import { OrganisationsRoutes } from "./Organisations/OrganisationsRoutes";
import { SettingsLayout } from "./SettingsLayout";

export function SettingsRoute() {
  const { userData } = useAuth();

  if (!userData) {
    return null;
  }

  if (!userData.roles?.includes(UserRole.SUPER_ADMIN)) {
    return <Navigate to="/" />;
  }

  return (
    <SettingsLayout>
      <Routes>
        <Route index element={<Navigate to="organisations" replace />} />
        <Route path="organisations/*" element={<OrganisationsRoutes />} />
      </Routes>
    </SettingsLayout>
  );
}

import { flexRender } from "@tanstack/react-table";
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TablePreHeader,
  TableRow,
} from "components/ui/table";
import { useTable } from "hooks/useTable";
import { DataTablePagination } from "components/ui/data-table-pagination";
import { useBacklist } from "contexts/BacklistContext";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "@dnd-kit/utilities";
import { columns } from "./columns";
import { PreHeader } from "./PreHeader";

import { BooksTableBody } from "./BooksTableBody";

export const BooksTable = () => {
  const { t } = useTranslation(["books"]);

  const { backlist, resultLength, pagination, setPagination, isLoadingSearch } =
    useBacklist();

  const { table } = useTable({
    columns: columns(t, { loading: isLoadingSearch }),
    data: backlist,
    enableRowSelection: true,
    totalRecords: resultLength || 0,
    paginationState: { pagination, setPagination },
  });

  const isAllPageRowsSelected = table.getIsAllPageRowsSelected();
  const [isSelectedAll, setIsSelectedAll] = useState(false);
  const prevBacklist = usePrevious(backlist);

  useEffect(() => {
    if (isSelectedAll && !isAllPageRowsSelected) {
      setIsSelectedAll(false);
    }
  }, [isSelectedAll, isAllPageRowsSelected]);

  useEffect(() => {
    if (backlist !== prevBacklist && isSelectedAll) {
      setIsSelectedAll(false);
      table.setRowSelection({});
    }
  }, [backlist, prevBacklist, isSelectedAll, table]);

  const handleSelectAllClick = useCallback(async () => {
    if (isSelectedAll) {
      setIsSelectedAll(false);
      table.setRowSelection({});
    } else {
      setIsSelectedAll(true);
      table.toggleAllPageRowsSelected(true);
    }
  }, [isSelectedAll, table]);

  const selectAllButtonLabel = isSelectedAll
    ? t("clearSelection")
    : t("selectAll", { count: resultLength || 0 });

  return (
    <div className="space-y-4 text-left">
      <Table>
        <TablePreHeader colSpan={table.getHeaderGroups()[0].headers.length}>
          <PreHeader
            table={table}
            isSelectedAll={isSelectedAll}
            allBooksCount={resultLength || 0}
          />
        </TablePreHeader>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <TableHead key={header.id} colSpan={header.colSpan}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </TableHead>
              ))}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody data-testid="books-table-body">
          <BooksTableBody
            columns={columns(t)}
            table={table}
            loading={isLoadingSearch}
          />
        </TableBody>
      </Table>
      <DataTablePagination
        table={table}
        pageSizeLabel={t("pageSize")}
        selectAllLabel={resultLength ? selectAllButtonLabel : undefined}
        onSelectAllClick={handleSelectAllClick}
      />
    </div>
  );
};

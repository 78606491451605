import { Icon, IconProps, IconSizes } from "../icon/Icon";
import { cn } from "../../utils";

interface ModalIconProps {
  className?: string;
  iconClassName?: string;
  iconSize?: IconSizes;
  pulse?: boolean;
  icon: IconProps["name"];
}

export const ModalIcon = ({
  className,
  iconClassName,
  icon,
  iconSize = "large",
  pulse = true,
}: ModalIconProps) => {
  return (
    <div
      className={cn(
        "relative rounded-full flex p-2.5 justify-center items-center bg-current",
        !pulse && "ring-4 ring-current ring-opacity-5",
        pulse && "p-4",
        className,
      )}
    >
      {pulse && (
        <div className="absolute inset-0 bg-current bg-opacity-25 rounded-full animate-ping duration-2000" />
      )}

      <Icon
        className="absolute"
        name={icon}
        size={iconSize}
        iconClassName={cn("h-full w-full text-current relative", iconClassName)}
      />
    </div>
  );
};

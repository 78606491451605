import { SignoutModal } from "components/modal/SignoutModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "components/ui/dropdown-menu";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { UserRole } from "__generated__/models";
import { RoutePath } from "shared/constants";
import { ProfilePicture } from "../user/ProfilePicture";

export const ProfileMenu = () => {
  const { t } = useTranslation(["general"]);
  const { authUser, userData } = useAuth();
  const location = useLocation();

  if (!authUser) {
    return null;
  }

  const isSuperAdmin = userData?.roles?.includes(UserRole.SUPER_ADMIN);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div data-testid="account-menu" className="px-2">
          <ProfilePicture
            name={userData?.givenName ?? userData?.email}
            photoUrl={authUser?.photoURL}
          />
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        className="w-56"
        side="bottom"
        align="end"
        sideOffset={30}
      >
        <DropdownMenuItem asChild>
          <Link
            to="/profile"
            state={{ prevPath: location.pathname, prevSearch: location.search }}
          >
            <p className="text-secondary-600">{t("general:header.profile")}</p>
          </Link>
        </DropdownMenuItem>

        <DropdownMenuItem asChild>
          <Link to={RoutePath.ContactUs} target="_blank">
            <p className="text-secondary-600">
              {t("components:header.contactUs")}
            </p>
          </Link>
        </DropdownMenuItem>

        {isSuperAdmin && (
          <DropdownMenuItem asChild>
            <Link to="/settings">
              <p className="text-secondary-600">
                {t("general:header.settings")}
              </p>
            </Link>
          </DropdownMenuItem>
        )}

        <DropdownMenuItem asChild>
          <SignoutModal />
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

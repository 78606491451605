import { useCallback, useState } from "react";
import { useAuth } from "contexts/AuthContext";
import { useQuery } from "react-query";
import { useApi } from "./useApi";
import { SearchQueryRequest } from "../__generated__/api";

const initialSearchParams: SearchQueryRequest = {
  model: "products",
  q: "",
  query_by: "title,author,biography",
  sort_by: "publishedAt:desc",
  filter_by: "",
  page: 1,
  per_page: 10,
};

export const useTypesense = () => {
  const { authUser } = useAuth();

  const api = useApi();

  const [params, setParams] = useState<Partial<SearchQueryRequest>>();

  const finalParams = { ...initialSearchParams, ...params };

  const { data, error, isLoading } = useQuery(
    ["productsSearch", ...Object.values(finalParams)],
    async () => api.search(finalParams),
    {
      enabled: !!params && !!authUser,
    },
  );

  const searchProducts = useCallback(
    (searchParams: Partial<SearchQueryRequest>) => {
      setParams(searchParams);
    },
    [],
  );

  return { data, error, isLoading, searchProducts, finalParams };
};

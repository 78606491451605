import { PropsWithChildren } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { PageTitle } from "components/page/PageTitle";
import { PageSubtitle } from "components/page/PageSubtitle";
import {
  OrganisationProvider,
  useOrganisation,
} from "contexts/OrganisationProvider";
import { last } from "lodash";
import { Tabs, TabsList, TabsTrigger } from "../../../../components/ui/tabs";

export function OrganisationPageLayout({ children }: PropsWithChildren) {
  const { t } = useTranslation("settings");

  const { organisation, members } = useOrganisation();

  const location = useLocation();

  const page = last(location.pathname.split("/"));

  return (
    <div className="py-10 space-y-8">
      <div className="px-28 border-b">
        <div className="space-y-1 mb-4">
          <PageTitle>{organisation?.name ?? `\u00A0`}</PageTitle>
          <PageSubtitle>
            {t("settings:organisation.overview.header.members", {
              count: members.length,
            })}
          </PageSubtitle>
        </div>

        <div className="-mb-1">
          <Tabs value={page}>
            <TabsList>
              <TabsTrigger value="members" asChild>
                <Link to="members">Members</Link>
              </TabsTrigger>
            </TabsList>
          </Tabs>
        </div>
      </div>

      <div className="px-28">{children}</div>
    </div>
  );
}

export function OrganisationLayout({ children }: PropsWithChildren) {
  const id = useParams().organisationId!;

  return (
    <OrganisationProvider id={id}>
      <OrganisationPageLayout>{children}</OrganisationPageLayout>
    </OrganisationProvider>
  );
}

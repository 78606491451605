import { useCallback, useState } from "react";

import {
  createGeneratedSubject,
  GenerationStatus,
  IProduct,
  updateProduct,
  updateProducts,
} from "integrations/firebase/collections";
import { useAuth } from "contexts/AuthContext";
import { logError } from "shared/services/ErrorReporting";
import { useAnalytics } from "contexts/AnalyticsContext";
import { FirestoreError } from "firebase/firestore";
import chunk from "lodash/chunk";
import { FirestoreProductSubject } from "__generated__/models";

export const useProductActions = () => {
  const { authUser, organisation } = useAuth();

  const [isLoadingGenerate, setIsLoadingGenerate] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [errorGenerate, setErrorGenerate] = useState<FirestoreError>();
  const [errorUpdate, setErrorUpdate] = useState<Error>();

  const { gaEvent } = useAnalytics();

  const generateKeywords = useCallback(
    async (products: IProduct[]) => {
      const BATCH_SIZE = 500;
      if (!products || products.length === 0) {
        return;
      }
      setIsLoadingGenerate(true);
      setErrorGenerate(undefined);
      try {
        const chunkArray = (array: any[], size: number) => {
          const chunks = [];
          for (let i = 0; i < array.length; i += size) {
            chunks.push(array.slice(i, i + size));
          }
          return chunks;
        };

        const batchId = new Date().getTime();
        const productChunks = chunkArray(products, BATCH_SIZE);
        // eslint-disable-next-line no-restricted-syntax
        for (const productChunk of productChunks) {
          const update = {
            "generated.keywords.status": GenerationStatus.REQUESTED,
            "generated.keywords.userId": authUser?.uid,
          };
          // eslint-disable-next-line no-await-in-loop
          await updateProducts(productChunk, update);
          productChunk.forEach((product) => {
            gaEvent({
              type: "bulk_generate_keywords",
              payload: {
                bulk_id: batchId,
                book: product.id,
                item_list_id: product.id,
                item_list_name: product.title,
              },
            });
          });
        }
      } catch (err) {
        logError(err);
        setErrorGenerate(err as FirestoreError);
      }

      setIsLoadingGenerate(false);
    },
    [gaEvent, authUser?.uid],
  );

  const generateDescriptions = useCallback(
    async (products: IProduct[], batch = 500) => {
      if (!products?.length) {
        return;
      }

      setIsLoadingGenerate(true);
      setErrorGenerate(undefined);

      try {
        const time = new Date().getTime();
        const chunks = chunk(products, batch);

        chunks.map(async (items) => {
          const generatedDescriptionPath = "generated.description";
          const generatedDescriptionDataPath = `${generatedDescriptionPath}.data`;

          const update = {
            [`${generatedDescriptionPath}.status`]: GenerationStatus.REQUESTED,
            [`${generatedDescriptionPath}.userId`]: authUser?.uid,
            [`${generatedDescriptionDataPath}.target`]: "",
            [`${generatedDescriptionDataPath}.details`]: "",
            [`${generatedDescriptionDataPath}.text`]: "",
            [`${generatedDescriptionDataPath}.isManualInput`]: false,
          };

          await updateProducts(items, update);

          items.forEach((product) => {
            gaEvent({
              type: "bulk_generate_descriptions",
              payload: {
                bulk_id: time,
                book: product.id,
                item_list_id: product.id,
                item_list_name: product.title,
              },
            });
          });
        });
      } catch (err) {
        logError(err);
        setErrorGenerate(err as FirestoreError);
      }

      setIsLoadingGenerate(false);
    },
    [gaEvent, authUser?.uid],
  );

  const saveFinalKeywords = useCallback(
    (idProduct: string) => async (keywords: string[]) => {
      setIsLoadingUpdate(true);
      try {
        setErrorUpdate(undefined);
        const uniqueFinalKeywords = new Set([...keywords]);
        const finalUniqueArray = Array.from(uniqueFinalKeywords);

        const update = {
          finalKeywords: finalUniqueArray,
        };
        await updateProduct(idProduct, update);
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }
      setIsLoadingUpdate(false);
    },
    [],
  );

  const updateProductById = useCallback(
    async (product: IProduct | string, getUpdateObject: () => any) => {
      setIsLoadingUpdate(true);
      try {
        setErrorUpdate(undefined);
        const id = typeof product === "string" ? product : product.id;
        const update = getUpdateObject();

        await updateProduct(id, update);
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }
      setIsLoadingUpdate(false);
    },
    [],
  );

  const generateProductKeywords = useCallback(
    async (product: IProduct, options?: { regenerate?: boolean }) => {
      setIsLoadingUpdate(true);
      try {
        setErrorUpdate(undefined);

        const { id } = product;
        await updateProductById(product, () => ({
          "generated.keywords.status": GenerationStatus.REQUESTED,
          "generated.keywords.userId": authUser?.uid,
        }));
        gaEvent({
          type: "generate_keywords",
          payload: {
            book: id,
            item_list_id: id,
            item_list_name: product?.title,
            regenerate: options?.regenerate,
          },
        });
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }
      setIsLoadingUpdate(false);
    },
    [updateProductById, gaEvent, authUser?.uid],
  );

  const generateProductDescription = useCallback(
    async (
      product: IProduct,
      target: string | null,
      details: string | null,
      options?: { regenerate?: boolean },
    ) => {
      const generatedDescriptionPath = "generated.description";
      const generatedDescriptionDataPath = `${generatedDescriptionPath}.data`;
      await updateProductById(product, () => ({
        [`${generatedDescriptionPath}.status`]: GenerationStatus.REQUESTED,
        [`${generatedDescriptionPath}.userId`]: authUser?.uid,
        [`${generatedDescriptionDataPath}.target`]: target,
        [`${generatedDescriptionDataPath}.details`]: details,
        [`${generatedDescriptionDataPath}.text`]: "",
        [`${generatedDescriptionDataPath}.isManualInput`]: false,
      }));
      gaEvent({
        type: "generate_description",
        payload: {
          book: product.id,
          item_list_id: product.id,
          item_list_name: product?.title,
          regenerate: options?.regenerate,
          target,
          details,
        },
      });
    },
    [updateProductById, authUser, gaEvent],
  );

  const generateProductSubjects = useCallback(
    async (productId: string, options?: { regenerate?: boolean }) => {
      if (!authUser?.uid) {
        return;
      }
      setIsLoadingGenerate(true);
      try {
        setErrorUpdate(undefined);
        await createGeneratedSubject(productId, authUser?.uid, {
          id: organisation?.id || "",
          name: organisation?.name || "",
        });
        gaEvent({
          type: "generate_subjects",
          payload: {
            book: productId,
            regenerate: options?.regenerate,
          },
        });
        setIsLoadingGenerate(false);
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
        setIsLoadingGenerate(false);
      }
    },
    [authUser?.uid, organisation, gaEvent],
  );

  const saveFinalSubjects = useCallback(
    async (productId: string, subjects: FirestoreProductSubject[]) => {
      setIsLoadingUpdate(true);
      try {
        setErrorUpdate(undefined);

        await updateProductById(productId, () => ({
          "subjects.final": subjects,
        }));
      } catch (err) {
        logError(err);
        setErrorUpdate(err as Error);
      }
      setIsLoadingUpdate(false);
    },
    [updateProductById],
  );

  const setProductDescription = useCallback(
    async (product: string, text: string) => {
      const generatedDescriptionDataPath = "generated.description.data";
      await updateProductById(product, () => ({
        [`${generatedDescriptionDataPath}.text`]: text,
        [`${generatedDescriptionDataPath}.userId`]: authUser?.uid,
        [`${generatedDescriptionDataPath}.isManualInput`]: true,
      }));
    },
    [updateProductById, authUser?.uid],
  );

  return {
    isLoadingGenerate,
    errorGenerate,
    generateKeywords,
    generateDescriptions,
    isLoadingUpdate,
    errorUpdate,
    generateProductKeywords,
    saveFinalKeywords,
    generateProductDescription,
    setProductDescription,
    generateProductSubjects,
    saveFinalSubjects,
  };
};

import { useAnalytics } from "contexts/AnalyticsContext";
import { cn } from "utils/cn";
import { Icon, IconProps } from "components/icon/Icon";
import React, { ButtonHTMLAttributes } from "react";

type ButtonSizes = "small" | "medium" | "large";
type ButtonVariants = "primary" | "secondary" | "tertiary" | "link" | "ghost";

export interface ButtonProps extends React.ComponentProps<"button"> {
  /**
   * Button contents
   */
  label?: string | JSX.Element;
  /**
   * Optional click handler
   */
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  /**
   * What style of button to use
   */
  variant?: ButtonVariants;
  /**
   * How large should the button be?
   */
  size?: ButtonSizes;
  /**
   * Is the button disabled?
   */
  disabled?: boolean;
  /**
   * Is the button warning?
   */
  warning?: boolean;
  /**
   * Icon
   */
  icon?: IconProps["name"];
  /**
   * iconPosition
   */
  iconPosition?: "left" | "right";
  /**
   * Button id
   */
  id?: string;
  /**
   * Extra classes
   */
  className?: string;
  /**
   * Extra classes for the text span
   */
  spanClassName?: string;
  /**
   * Test id
   */
  testId?: string;
  /**
   * GA id
   */
  analyticsId?: string;
  /**
   * GA data
   */
  analyticsData?: { [key: string]: any };
  /**
   * Remove border rounding
   */
  removeRounding?: "left" | "right";
  /**
   * Passing styles to icon
   */
  iconClassName?: string;

  autoFocus?: boolean;

  type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
}

const baseClasses =
  "inline-flex items-center gap-x-2 font-medium whitespace-nowrap";

export const variantClasses = {
  primary: `
  ui-btn-variant-primary
  rounded-lg
  border
  border-transparent
  text-white
  transition duration-300 ease-in-out
  relative
  z-10

  before:content-['']
  before:absolute
  before:w-full
  before:h-full
  before:top-0
  before:left-0
  before:rounded-lg
  before:bg-gradient-to-br before:from-cta-hover-gradient-from before:to-cta-hover-gradient-to
  before:transition duration-300 ease-in-out
  before:-z-10

  after:content-['']
  after:absolute
  after:w-full
  after:h-full
  after:top-0
  after:left-0
  after:rounded-lg
  after:bg-gradient-to-br from-cta-gradient-from to-cta-gradient-to
  after:opacity-100
  after:transition duration-300 ease-in-out
  after:hover:opacity-0
  after:-z-10

  focus:shadow-outline-primary
  `,
  secondary: `
  ui-btn-variant-secondary
  rounded-lg
  border
  border-primary
  text-primary
  transition background duration-300 ease-in-out

  hover:border-primary-600
  hover:text-primary-700
  hover:bg-primary-25
  focus:shadow-outline-primary
  `,
  tertiary: `
  ui-btn-variant-tertiary
  rounded-lg
  border
  border-secondary-200
  bg-white
  text-secondary-600
  shadow-sm

  hover:bg-secondary-25
  hover: border-secondary-300

  focus:shadow-outline-secondary
  `,
  link: `
  ui-btn-variant-link
  border-transparent
  text-primary-600
  rounded-lg
  hover:text-primary-800
  hover:bg-primary-50
  `,
  ghost: `
  border-transparent
  text-primary-600
  hover:text-primary-800
  `,
};

const warningClasses = {
  primary: `
    ui-btn-warning
    after:bg-gradient-to-br after:from-system-warning after:to-system-warning
    before:bg-gradient-to-br before:from-system-warningHover before:to-system-warningHover
  `,
  secondary: `
    ui-btn-warning
    border-system-warning
    hover:border-system-warningHover
    hover:text-system-warningHover
  `,
  tertiary: `
    ui-btn-warning
    text-system-warning
    border-system-warning
    hover:border-system-warningHover
    hover:text-system-warningHover
  `,
  link: `
    ui-btn-warning
    text-system-warning
    hover:text-system-warningHover
  `,
  ghost: `
    text-system-warning
    hover:text-system-warningHover
  `,
};

const getSpanClasses = (variant: ButtonVariants, warning: boolean): string => {
  if (variant === "link" && !warning)
    return "group-hover:after:border-b-primary-800";
  if (variant === "link" && warning)
    return "group-hover:after:border-b-system-warningHover";

  return "";
};

const borderRoundingClasses = {
  left: "rounded-l-none pl-2 before:rounded-l-none after:rounded-l-none",
  right: "rounded-r-none pr-2 before:rounded-r-none after:rounded-r-none",
};

const sizeClasses = {
  small: "ui-btn-size-small py-2 px-4 text-xs",
  medium: "ui-btn-size-medium py-3 px-4 text-sm ",
  large: "ui-btn-size-large py-3 px-4 text-base ",
};

const disabledClasses = "opacity-50 pointer-events-none";

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      id,
      variant = "primary",
      size = "medium",
      label,
      disabled = false,
      icon = undefined,
      iconPosition = "left",
      className = "",
      spanClassName = "",
      analyticsId = undefined,
      analyticsData = {},
      onClick = undefined,
      removeRounding,
      warning = false,
      testId,
      iconClassName,
      autoFocus,
      type = "button",
      children,
      ...props
    }: ButtonProps,
    ref,
  ) => {
    const analytics = useAnalytics();

    const btnBorderClasses = removeRounding
      ? borderRoundingClasses[removeRounding]
      : "";
    const btnWarningClasses = warning ? warningClasses[variant] : "";
    const btnDisabledClasses = disabled ? disabledClasses : "";
    const btnSpanClasses = `${getSpanClasses(variant, warning)} ${spanClassName}`;

    return (
      <button
        ref={ref}
        // eslint-disable-next-line react/button-has-type
        type={type}
        id={id}
        className={cn(
          baseClasses,
          sizeClasses[size],
          variantClasses[variant],
          btnBorderClasses,
          btnWarningClasses,
          btnDisabledClasses,
          "group",
          className,
        )}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        disabled={disabled}
        data-testid={testId}
        onClick={async (e) => {
          if (analyticsId && analytics?.gaEvent) {
            analytics.gaEvent({
              type: "click",
              payload: {
                analytics_id: analyticsId,
                ...analyticsData,
              },
            });
          }

          if (onClick) {
            return onClick(e);
          }
        }}
        {...props}
      >
        {iconPosition === "left" && icon && (
          <Icon name={icon} size={size} iconClassName={iconClassName} />
        )}
        {label ? (
          <span
            className={`relative flex flex-row ${btnSpanClasses}`}
            style={{ margin: "0 auto" }}
          >
            {label}
          </span>
        ) : null}

        {children}

        {iconPosition === "right" && icon && (
          <Icon name={icon} size={size} iconClassName={iconClassName} />
        )}
      </button>
    );
  },
);
Button.displayName = "Button";

import { createContext, PropsWithChildren, useContext, useMemo } from "react";
import {
  getUsersCollection,
  IOrganisation,
  IUser,
} from "integrations/firebase/collections";
import { useCollectionData } from "react-firebase-hooks/firestore";
import { orderBy, query, where } from "firebase/firestore";
import { useOrganisations } from "./OrganisationsProvider";

interface IOrganisationContext {
  organisation?: IOrganisation;
  members: IUser[];
  loading: boolean;
}

export const OrganisationContext = createContext<IOrganisationContext>(
  undefined as never,
);

interface OrganisationProviderProps {
  id: string;
}

export const OrganisationProvider = ({
  id,
  children,
}: PropsWithChildren<OrganisationProviderProps>) => {
  const { organisations, loading } = useOrganisations();

  const organisation = organisations.find((o) => o.id === id);

  const [members = []] = useCollectionData(
    query(
      getUsersCollection(),
      where("organisation.id", "==", id),
      orderBy("email"),
    ),
  );

  const value = useMemo(
    () => ({
      members,
      organisation,
      loading,
    }),
    [members, organisation, loading],
  );

  return (
    <OrganisationContext.Provider value={value}>
      {children}
    </OrganisationContext.Provider>
  );
};

export const useOrganisation = () => {
  const context = useContext(OrganisationContext);

  if (!context) {
    throw new Error(
      "useOrganisation must be used within a OrganisationProvider",
    );
  }

  return context;
};

import { ColumnDef } from "@tanstack/react-table";
import { IUser } from "integrations/firebase/collections";
import { TFunction } from "i18next";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";
import { ProfilePicture } from "components/user/ProfilePicture";

export const columns: (t: TFunction) => ColumnDef<IUser>[] = (t: TFunction) => [
  {
    accessorKey: "email",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("settings:organisation.overview.table.columns.email")}
      />
    ),
    cell: ({ row }) => (
      <div className="flex space-x-4 items-center">
        <ProfilePicture
          photoUrl={row.original.photoUrl}
          name={row.original.givenName ?? row.original.email}
        />

        <div>{row.getValue("email")}</div>
      </div>
    ),
  },
];

import { Button } from "components/button";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "shared/constants";

export const CompactPreHeader = () => {
  const { t } = useTranslation(["components"]);
  const navigate = useNavigate();

  const handleGoToProjects = () => {
    navigate(RoutePath.Projects);
  };
  return (
    <div className="flex flex-row justify-between items-center w-full">
      <p className="text-m font-medium text-secondary-700">
        {t("components:table.description")}
      </p>
      <Button
        variant="link"
        icon="arrow-right"
        onClick={handleGoToProjects}
        label={t("components:table.allProjects")}
      />
    </div>
  );
};

import { ColumnDef } from "@tanstack/react-table";
import { IOrganisation } from "integrations/firebase/collections";

import { TFunction } from "i18next";
import { DataTableColumnHeader } from "components/ui/data-table-column-header";

export const columns: (t: TFunction) => ColumnDef<IOrganisation>[] = (
  t: TFunction,
) => [
  {
    accessorKey: "name",
    header: ({ column }) => (
      <DataTableColumnHeader
        column={column}
        title={t("settings:organisations.overview.table.columns.name")}
      />
    ),
    cell: ({ row }) => <div className="w-[40%]">{row.getValue("name")}</div>,
  },
];

import { Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import { OrganisationLayout } from "./OrganisationLayout";
import { OrganisationMembersPage } from "./members/OrganisationMembersPage";

export function OrganisationRoutes() {
  return (
    <OrganisationLayout>
      <Routes>
        <Route index element={<Navigate to="members" replace />} />
        <Route path="members" element={<OrganisationMembersPage />} />
      </Routes>
    </OrganisationLayout>
  );
}

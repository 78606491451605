import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithPopup,
  updateProfile,
  User,
  UserCredential,
} from "firebase/auth";
import { doc, onSnapshot, updateDoc } from "firebase/firestore";

import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "integrations/firebase/auth";
import {
  getOrganisationsCollection,
  getUsersCollection,
  IOrganisation,
  IUser,
} from "integrations/firebase/collections";
import { GlobalLoading } from "components/loading";
import { logError } from "shared/services/ErrorReporting";

export interface IAuthContext {
  userData: IUser | null;
  organisation: IOrganisation | null;
  authUser?: User | null;
  loading: boolean;
  isLoggedIn: boolean;
  isLoadingUpdate: boolean;
  authToken?: string;

  signInWithOauth(type: OAuthProviderType): Promise<UserCredential>;

  sendLoginLink(email: string): Promise<void>;

  signOut(): Promise<void>;

  setSettingsVisitedSearchTermsPage(): Promise<void>;

  updateUserProfile(profile: UserProfile): Promise<void>;
}

export const AuthContext = createContext<IAuthContext>(undefined as never);

export const useAuth = () => useContext(AuthContext);

const OAUTH_PROVIDERS = {
  google: new GoogleAuthProvider(),
};

type OAuthProviderType = keyof typeof OAUTH_PROVIDERS;

export interface UserProfile {
  firstName: string;
  lastName: string;
}

export const AuthContextProvider = ({ children }: PropsWithChildren) => {
  const [authToken, setAuthToken] = useState<string>();
  const [userData, setUserData] = useState<IUser | null>(null);
  const [organisation, setOrganisation] = useState<IOrganisation | null>(null);
  const [authUser, loading] = useAuthState(auth, {
    async onUserChanged(user) {
      const idToken = user ? await user.getIdToken() : undefined;

      setAuthToken(idToken);
    },
  });
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);

  useEffect(() => {
    if (!authUser) {
      setUserData(null);
      return;
    }

    const uid = auth.currentUser?.uid;
    const userRef = doc(getUsersCollection(), uid);

    const userSubscription = onSnapshot(userRef, (document) => {
      const user = document.data();

      if (!user) {
        logError(`User not found for id: ${uid}`);
        console.warn("User not found for id", uid);
        setUserData(null);
        return;
      }

      setUserData(user);
    });

    return () => {
      if (userSubscription) {
        userSubscription();
      }
    };
  }, [authUser]);

  const updateUserProfile = useCallback(
    async (profile: UserProfile) => {
      if (!authUser) {
        return;
      }

      const userRef = doc(getUsersCollection(), authUser.uid);

      await updateProfile(authUser, {
        displayName: `${profile.firstName} ${profile.lastName}`,
      });

      await updateDoc(userRef, {
        givenName: profile.firstName,
        familyName: profile.lastName,
      });
    },
    [authUser],
  );

  useEffect(() => {
    if (!userData) {
      setOrganisation(null);
      return;
    }

    const organisationRef = doc(
      getOrganisationsCollection(),
      userData.organisation.id,
    );

    const unsubscribeOrganisation = onSnapshot(organisationRef, (document) => {
      const org = document.data();
      if (!org) {
        logError(
          `Organisation not found for organisationId: ${userData.organisation.id}`,
        );
        console.warn(
          "Organisation not found for organisationId, userId",
          userData.organisation.id,
          userData.id,
        );
        setOrganisation(null);
        return;
      }
      setOrganisation(org);
    });

    return () => {
      if (unsubscribeOrganisation) {
        unsubscribeOrganisation();
      }
    };
  }, [userData]);

  const signInWithOauth = useCallback(async (type: OAuthProviderType) => {
    const provider = OAUTH_PROVIDERS[type];

    if (!provider) {
      throw new Error(`No provider found for type ${type}`);
    }

    return signInWithPopup(auth, provider);
  }, []);

  const sendLoginLink = useCallback((email: string) => {
    try {
      window.localStorage.setItem("emailForSignIn", email);

      return sendSignInLinkToEmail(auth, email, {
        handleCodeInApp: true,
        url: `${window.location.origin}/login-link`,
      });
    } catch (error) {
      window.localStorage.removeItem("emailForSignIn");

      throw error;
    }
  }, []);

  const signOut = useCallback(async () => {
    return auth.signOut();
  }, []);

  const setSettingsVisitedSearchTermsPage = useCallback(async () => {
    setIsLoadingUpdate(true);
    try {
      const userRef = doc(getUsersCollection(), authUser?.uid);
      await updateDoc(userRef, {
        settings: {
          visitedSearchTermsPage: true,
        },
      });
      setIsLoadingUpdate(false);
    } catch (error) {
      logError(error);
      setIsLoadingUpdate(false);
      throw error;
    }
  }, [authUser]);

  const isLoggedIn = !!authUser;
  const authProviderValue = useMemo(
    () => ({
      authUser,
      userData,
      organisation,
      loading,
      isLoggedIn,
      signInWithOauth,
      signOut,
      setSettingsVisitedSearchTermsPage,
      isLoadingUpdate,
      sendLoginLink,
      updateUserProfile,
      authToken,
    }),
    [
      authUser,
      organisation,
      userData,
      loading,
      isLoggedIn,
      signInWithOauth,
      signOut,
      setSettingsVisitedSearchTermsPage,
      isLoadingUpdate,
      sendLoginLink,
      updateUserProfile,
      authToken,
    ],
  );

  return (
    <AuthContext.Provider value={authProviderValue}>
      {loading ? <GlobalLoading /> : children}
    </AuthContext.Provider>
  );
};

import { collection, CollectionReference, Timestamp } from "firebase/firestore";

import { db } from "integrations/firebase/firestore";
import { converter } from "../utils";

export interface IOrganisation {
  id: string;
  name: string;
  createdAt?: Timestamp;
  targets?: string[];
}

export const getOrganisationsCollection =
  (): CollectionReference<IOrganisation> =>
    collection(db, "organisations").withConverter(converter);

/**
 * The maximum amount of books that can be selected
 * for generating metadata
 */
export const GENERATE_METADATA_BOOKS_LIMIT = 50;

/**
 * The maximum amount of books that can be selected,
 * when prepared to be saved as a project
 */
export const SAVE_AS_PROJECT_BOOKS_LIMIT = 250;

/**
 * The amount of books that can be selected for project creation,
 * before we trigger a warning dialog, explaining that the
 * project might be hard to manage.
 */
export const SAVE_AS_PROJECT_BOOKS_WARNING_LIMIT = 100;

export enum RoutePath {
  EMPTY = "/",
  Home = "/home",
  Login = "/login",
  LoginLink = "/login-link",
  Profile = "/profile",
  ContactUs = "/contact-us",
  Books = "/books",
  SearchTerms = "/search-terms",
  SelectedSearchTerms = "/search-terms/selected-books",
  Projects = "/projects",
  ProjectDetails = "/projects/:idProject",
  ProductDetails = "/projects/:idProject/product/:idProduct",
}
